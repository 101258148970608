import { render, staticRenderFns } from "./SsoLoginButton.vue?vue&type=template&id=0ea82004"
import script from "./SsoLoginButton.vue?vue&type=script&lang=ts"
export * from "./SsoLoginButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkIcon: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkIcon/RkIcon.vue').default,RkButton: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkButton/RkButton.vue').default})
