var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{ref:"header",staticClass:"rk-header",class:{
    'fixed top-0 w-screen transition-transform duration-300 will-change-transform': _vm.isSticky,
    hidden: _vm.hidden,
    'border-b border-divider-base': _vm.isSimple,
  }},[(!_vm.isSimple)?_c('div',{staticClass:"relative z-40 bg-primary-dark text-white lg:z-30"},[_c('RkSiteWrapper',{attrs:{"width":"outer"}},[_c('div',{staticClass:"flex h-10 items-center"},[_vm._t("sites"),_vm._v(" "),_c('div',{staticClass:"ml-auto flex h-10 items-center space-x-4 transition-opacity lg:space-x-3"},[_vm._t("sponsor"),_vm._v(" "),_vm._t("localisation"),_vm._v(" "),_vm._t("header-icons",function(){return [(_vm.accountIcon)?_c('RkButton',{staticClass:"rk-button--pure rk-header__action ml-1",attrs:{"data-testid":"accountIcon","aria-label":"Account"},on:{"click":function($event){return _vm.$emit('click:account')}}},[_c('RkIcon',{class:{
                  'rk-header__icon is-active': _vm.activeIcon === 'account',
                },attrs:{"icon":_vm.accountIcon,"size":"1.25rem"}})],1):_vm._e(),_vm._v(" "),(_vm.wishlistIcon)?_c('RkButton',{staticClass:"rk-button--pure rk-header__action",attrs:{"data-testid":"wishlistIcon","aria-label":"Wishlist"},on:{"click":function($event){return _vm.$emit('click:wishlist')}}},[_c('RkIcon',{staticClass:"rk-header__icon",class:{
                  'rk-header__icon is-active': _vm.activeIcon === 'wishlist',
                },attrs:{"icon":_vm.wishlistIcon,"has-badge":_vm.wishlistHasProducts,"badge-label":_vm.wishlistItemsQty,"size":"1.25rem"}})],1):_vm._e(),_vm._v(" "),(_vm.cartIcon)?_c('RkButton',{staticClass:"rk-button--pure rk-header__action",attrs:{"data-testid":"cartIcon","aria-label":"Cart"},on:{"click":function($event){return _vm.$emit('click:cart')}}},[_c('RkIcon',{staticClass:"rk-header__icon",class:{
                  'rk-header__icon is-active': _vm.activeIcon === 'cart',
                },attrs:{"icon":_vm.cartIcon,"has-badge":_vm.cartHasProducts,"badge-label":_vm.cartItemsQty,"size":"1.25rem"}})],1):_vm._e()]},null,{
              activeIcon: _vm.activeIcon,
              cartHasProducts: _vm.cartHasProducts,
              cartItemsQty: _vm.cartItemsQty,
              cartIcon: _vm.cartIcon,
              wishlistIcon: _vm.wishlistIcon,
              accountIcon: _vm.accountIcon,
            }),_vm._v(" "),_vm._t("menutoggle")],2)],2)])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative z-30 bg-primary-light lg:z-10"},[_c('RkSiteWrapper',{attrs:{"width":"outer"}},[_c('div',{staticClass:"flex items-center justify-between",class:{
          'h-[45px] lg:h-[70px]': !_vm.isSimple,
          'h-[35px] lg:h-[60px]': _vm.isSimple,
        }},[_c('div',{staticClass:"flex max-w-logo flex-shrink-0 items-center md:max-w-logo-md lg:max-w-logo-lg xl:max-w-logo-xl"},[_vm._t("logo",function(){return [_c('RkLink',{staticClass:"flex h-full w-logo flex-shrink-0 items-center lg:w-logo-md xl:w-logo-lg",attrs:{"link":"/"}},[(_vm.logo)?_c('RkImage',{staticClass:"rk-header__logo relative z-10 h-logo w-logo lg:h-logo-md lg:w-logo-md xl:h-logo-lg xl:w-logo-lg",attrs:{"src":_vm.logo,"alt":_vm.title,"width":_vm.logoWidth,"height":_vm.logoHeight,"loading":'eager'}}):_c('h1',{staticClass:"rk-header__title"},[_vm._v(_vm._s(_vm.title))])],1)]},null,{ logo: _vm.logo, title: _vm.title })],2),_vm._v(" "),(!_vm.isSimple)?_c('div',{staticClass:"m-auto hidden h-full w-full lg:flex lg:justify-center lg:self-stretch"},[_c('div',{staticClass:"flex h-full space-x-8 px-3"},[_c('nav',{staticClass:"flex",class:{ 'is-visible': _vm.isNavVisible }},[_vm._t("navigation")],2)])]):_vm._e(),_vm._v(" "),(!_vm.isSimple)?_c('div',{staticClass:"ml-auto flex h-full w-search flex-shrink-0 items-center sm:w-search-sm md:w-search-md lg:w-search-lg xl:w-search-xl"},[_vm._t("search",null,null,{ searchValue: _vm.searchValue, searchPlaceholder: _vm.searchPlaceholder })],2):_vm._e(),_vm._v(" "),(_vm.isSimple && _vm.isCheckout)?_c('div',{staticClass:"ml-auto flex items-start text-base font-bold text-white md:text-xl"},[_c('RkIcon',{staticClass:"mr-1 h-5 w-5 md:mt-[2px]",attrs:{"icon":"padlock"}}),_c('span',{staticClass:"md:ml-2"},[_vm._v(_vm._s(_vm.$t('Secure Checkout')))])],1):_vm._e()])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }