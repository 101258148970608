//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, onMounted, useContext } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    srcsets: {
      type: Array,
      default: () => [],
      validator: (value) =>
        value.length === 0 ||
        value.every((item) => item.resolution && item.src) ||
        value.every((item) => item.src && item.width),
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: null,
      validator: (value) => !isNaN(value) || value === null,
    },
    height: {
      type: [Number, String],
      default: null,
      validator: (value) => !isNaN(value) || value === null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
      validator: (value) => ['', 'lazy', 'eager'].includes(value),
    },
    imageTag: {
      type: String,
      default: 'img',
      validator: (value) => ['', 'img', 'nuxt-img', 'nuxt-picture'].includes(value),
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    withTransition: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: 'webp',
    },
  },
  setup(props, { attrs }) {
    const loaded = ref(false);
    const error = ref(false);
    const { app } = useContext();

    const computedTransition = computed(() => {
      return props.withTransition && 'fade';
    });

    const sortedSrcsets = computed(() => {
      const arr = [...props.srcsets];

      arr.sort((setA, setB) =>
        setA.width && setB.width
          ? Number.parseInt(setA.width) - Number.parseInt(setB.width)
          : Number.parseInt(setA.resolution) - Number.parseInt(setB.resolution)
      );
      return arr;
    });

    const srcset = computed(() => {
      if (sortedSrcsets.value.length === 0) return null;
      return sortedSrcsets.value.sortedSrcsets.reduce(
        (str, set) => `${prefix(str)}${set.src} ${srcsetDescriptor(set)}`,
        ''
      );
    });

    const sizes = computed(() => {
      const hasBreakpoints = sortedSrcsets.value.every((set) => set.breakpoint && set.width);
      if (!hasBreakpoints) return null;
      return sortedSrcsets.value.reduce(
        (str, set) =>
          `${prefix(str)}${formatBreakpoint(set.breakpoint)}${formatDimension(set.width)}`,
        ''
      );
    });

    const classes = computed(() => {
      if (loaded.value) {
        return 'rk-image rk-image-loaded';
      } else {
        return 'rk-image';
      }
    });

    const imageComponentTag = computed(() => {
      return !app.nuxt ? 'img' : props.imageTag || 'img';
    });

    const isPlaceholderVisible = computed(() => {
      return (
        imageComponentTag.value === 'nuxt-img' ||
        imageComponentTag.value === 'nuxt-picture' ||
        loaded.value ||
        (!loaded.value && !props.placeholder)
      );
    });

    const attributes = computed(() => {
      return props.imageTag === 'img' || props.imageTag === ''
        ? {
            ...attrs,
            sizes: sizes.value,
            srcset: srcset.value,
            format: props.format,
          }
        : {
            ...attrs,
            width: props.width ? props.width : null,
            height: props.height ? props.height : null,
            format: props.format,
            ...props.nuxtImgConfig,
          };
    });

    const styles = computed(() => {
      if (!props.width && !props.srcset && (props.imageTag === 'img' || props.imageTag === '')) {
        console.error(`Missing required prop width.`);
      }
      if (!props.height && !props.srcset && (props.imageTag === 'img' || props.imageTag === '')) {
        console.error(`Missing required prop height.`);
      }
      const sizeHandler = (size) => {
        return size === null ? null : `${size}px`;
      };
      return {
        '--_image-width': sizeHandler(props.width),
        '--_image-height': sizeHandler(props.height),
      };
    });

    onMounted(() => {
      if (imageComponentTag.value === 'nuxt-img' || imageComponentTag.value === 'nuxt-picture')
        loaded.value = true;
    });

    const onLoad = () => {
      loaded.value = true;
    };

    const onError = () => {
      error.value = true;
    };

    const formatResolution = (resolution) => {
      return ('' + resolution).endsWith('x') ? resolution : `${resolution}x`;
    };

    const formatDimension = (size) => {
      if (typeof size === null) return;
      if (
        ['%'].includes(`${size}`.slice(-1)) ||
        ['rem'].includes(`${size}`.slice(-3)) ||
        ['em', 'px', 'vw', 'vh'].includes(`${size}`.slice(-2)) ||
        !parseInt(size, 10)
      ) {
        return size;
      } else {
        return `${size}px`;
      }
    };

    const formatBreakpoint = (breakpoint) => {
      return breakpoint ? `(max-width: ${breakpoint}px) ` : '';
    };

    const prefix = (str) => {
      return str ? `${str}, ` : '';
    };

    const srcsetDescriptor = (srcset) => {
      return srcset.width
        ? `${Number.parseInt(srcset.width) || ''}w`
        : formatResolution(srcset.resolution);
    };

    return {
      loaded,
      error,
      computedTransition,
      classes,
      imageComponentTag,
      isPlaceholderVisible,
      attributes,
      styles,
      onLoad,
      onError,
    };
  },
});
