


































































































































































































import {
  computed,
  ref,
  reactive,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  watch,
} from '@nuxtjs/composition-api';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useUiState, useUiHelpers, useConfig } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SsoLogin from '~/modules/sso/components/SsoLogin.vue';
import SsoLoginButton from '~/modules/sso/components/SsoLoginButton.vue';
import { isStoryblok } from '~/helpers/isStoryblok';
import { useStoryblokBridge } from '@storyblok/nuxt-2';
import { useStoryblokStore } from '~/modules/storyblok/stores/storyblok';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  components: {
    LazyHydrate,
    HeaderLogo,
    HeaderNavigation,
    SsoLogin,
    SsoLoginButton,
    MobileNavigation: () => import('~/components/Header/Navigation/MobileNavigation.vue'),
    InstantSearch: () => import('~/modules/algolia/components/Header/InstantSearch.vue'),
    LocalisationWrapper: () => import('~/modules/localisation/components/LocalisationWrapper.vue'),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { config } = useConfig();
    const {
      isMobileMenuOpen,
      toggleMobileMenu,
      isMegaMenuOpen,
      isLocalisationOpen,
      isHeaderSticky,
      toggleLocalisation,
      toggleCartSidebar,
      isCurrencyOpen,
      toggleCurrency,
      isGlobalLinksOpen,
      toggleGlobalLinks,
      isCartSidebarOpen,
      isSearchOpen,
    } = useUiState();
    const { setTermForUrl } = useUiHelpers();
    const { isAuthenticated, load: loadUser, user } = useUser();
    const { cart, load: loadCart } = useCart();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();

    const productSearchResults = ref<ProductInterface[] | null>(null);

    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));
    const categoryTree = ref<CategoryTree[]>([]);

    const storyblok = useStoryblokStore();
    const story = ref(null);
    const socialLinks = ref(null);
    const inStoryblok = ref(false);

    const globalSiteLinks = reactive({
      store_gsl_label: config.value.store_gsl_label || null,
      store_gsl_url: config.value.store_gsl_url || null,
      com_gsl_label: config.value.com_gsl_label || null,
      com_gsl_url: config.value.com_gsl_url || null,
      tickets_gsl_label: config.value.tickets_gsl_label || null,
      tickets_gsl_url: config.value.tickets_gsl_url || null,
    });

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        await router.push(app.localeRoute({ name: 'login' }));
      }
    };

    useFetch(async () => {
      // Global site links form magento config
      // globalSiteLinks.value = await getGlobalSiteLinks();

      // Categories from magento
      await categoriesListLoad({ pageSize: 20 });
      categoryTree.value = categoryList.value?.[0]?.children.filter(
        (category) => category.include_in_menu
      );

      // Storyblok content
      await storyblok.load();
      story.value = storyblok.global.content.header[0];
      socialLinks.value = storyblok.global.content.bottom[1].socialLinks;

      // load user
      if (isAuthenticated && user.value === null) {
        await loadUser();
      }
    });

    onMounted(async () => {
      await loadCart();

      if (story.value && story.value.id) {
        useStoryblokBridge(story.value.id, (evStory) => {
          story.value = evStory;
        });
      }

      // check if we are in Storyblok editor
      inStoryblok.value = isStoryblok();
    });

    // watch isAuthenticated and loadUser when true
    watch(isAuthenticated, async (value) => {
      if (value) {
        if (user.value === null) {
          await loadUser();
        }
      }
    });

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      isMobileMenuOpen,
      toggleMobileMenu,
      toggleLocalisation,
      isLocalisationOpen,
      isHeaderSticky,
      isMegaMenuOpen,
      isCartSidebarOpen,
      toggleCartSidebar,
      globalSiteLinks,
      isCurrencyOpen,
      toggleCurrency,
      inStoryblok,
      story,
      isGlobalLinksOpen,
      toggleGlobalLinks,
      socialLinks,
    };
  },
});
