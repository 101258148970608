import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=3823928e"
import script from "./AppHeader.vue?vue&type=script&lang=ts"
export * from "./AppHeader.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkLink: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkLink/RkLink.vue').default,RkIcon: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkIcon/RkIcon.vue').default,RkButton: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkButton/RkButton.vue').default,RkBadge: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkBadge/RkBadge.vue').default,RkHeader: require('/var/www_vsf/htdocs/rockitUI/components/components/organisms/RkHeader/RkHeader.vue').default,RkOverlay: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkOverlay/RkOverlay.vue').default})
