//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkIcon',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const iconSizeClass = computed(() => {
      if (!props.width || !props.height) {
        switch (props.size) {
          case 'xxs':
            return 'w-3 h-3';
          case 'xs':
            return 'w-5 h-5';
          case 'sm':
            return 'w-8 h-8';
          case 'md':
            return 'w-10 h-10';
          case 'lg':
            return 'w-12 h-12';
          case 'xl':
            return 'w-14 h-14';
          case 'xxl':
            return 'w-16 h-16';
          case 'xl3':
            return 'w-20 h-20';
          case 'xl4':
            return 'w-24 h-24';
          default:
            return 'w-5 h-5';
        }
      } else {
        return '';
      }
    });
    return {
      iconSizeClass,
    };
  },
});
