//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '~/rockitUI/components/utilities/directives';
import { defineComponent, ref, computed, nextTick, watch } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkSearchBar',
  directives: {
    focus,
  },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, attrs }) {
    const search = ref(null);

    const listeners = computed(() => {
      return {
        ...attrs,
        input: (event) => emit('input', event.target.value),
        'keyup.enter': (event) => emit('input', event.target.value),
        'keyup.esc': () => emit('input', ''),
        blur: () => emit('blur'),
      };
    });

    const focusInput = () => {
      nextTick(() => {
        search.value.focus();
      });
    };

    watch(
      () => props.isSearchOpen,
      (value) => {
        if (value) focusInput();
      }
    );

    return {
      search,
      listeners,
      focusInput,
    };
  },
});
