//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Headroom from 'headroom.js';
import { isClient } from '~/rockitUI/components/utilities/helpers';
import RkImage from '~/rockitUI/components/components/atoms/RkImage/RkImage.vue';
import RkButton from '~/rockitUI/components/components/atoms/RkButton/RkButton.vue';
import RkIcon from '~/rockitUI/components/components/atoms/RkIcon/RkIcon.vue';
import RkLink from '~/rockitUI/components/components/atoms/RkLink/RkLink.vue';
import RkOverlay from '~/rockitUI/components/components/atoms/RkOverlay/RkOverlay.vue';
import RkSearchBar from '~/rockitUI/components/components/molecules/RkSearchBar/RkSearchBar.vue';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RkHeader',
  components: {
    RkImage,
    RkSearchBar,
    RkButton,
    RkIcon,
    RkLink,
    RkOverlay,
  },
  props: {
    logo: {
      type: [String, Object],
      default: '/images/rk-logo@2x.png',
    },
    logoHeight: {
      type: Number,
      default: 93,
    },
    logoWidth: {
      type: Number,
      default: 93,
    },
    title: {
      type: String,
      default: 'Rockit',
    },
    cartIcon: {
      type: [String, Boolean, Array],
      default: 'empty_cart',
    },
    wishlistIcon: {
      type: [String, Boolean, Array],
      default: 'heart',
    },
    accountIcon: {
      type: [String, Boolean, Array],
      default: 'profile',
    },
    activeIcon: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'account', 'wishlist', 'cart'].includes(value);
      },
    },
    searchPlaceholder: {
      type: String,
      default: 'Search for items',
    },
    searchValue: {
      type: String,
      default: '',
    },
    wishlistItemsQty: {
      type: [String, Number],
      default: '0',
    },
    cartItemsQty: {
      type: [String, Number],
      default: '0',
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    isNavVisible: {
      type: Boolean,
      default: false,
    },
    isLocalisationOpen: {
      type: Boolean,
      default: false,
    },
    isCurrencyOpen: {
      type: Boolean,
      default: false,
    },
    isMobileMenuOpen: {
      type: Boolean,
      default: false,
    },
    isSimple: {
      type: Boolean,
      default: false,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const hidden = ref(false);
    const sticky = ref(false);
    const headroom = ref(null);
    const header = ref(null);

    const cartHasProducts = computed(() => {
      return parseInt(props.cartItemsQty, 10) > 0;
    });
    const wishlistHasProducts = computed(() => {
      return parseInt(props.wishlistItemsQty, 10) > 0;
    });

    const headroomHandler = () => {
      if (!isClient) return;
      const options = {
        offset: 110,
        tolerance: {
          up: 5,
          down: 0,
        },
        onPin: function () {
          document.body.classList.add('header-pinned');
        },
        onUnpin: function () {
          document.body.classList.remove('header-pinned');
        },
      };
      headroom.value = new Headroom(header, options);
      headroom.value.init();
    };

    onMounted(() => {
      if (props.isSticky && !props.isSimple) {
        headroomHandler();
      }
    });

    onBeforeUnmount(() => {
      if (props.isSticky && headroom.value && !props.isSimple) {
        headroom.value.destroy();
      }
    });

    return {
      header,
      hidden,
      sticky,
      headroom,
      cartHasProducts,
      wishlistHasProducts,
    };
  },
});
