import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const checkSsoCustomerExistsCommand = {
  execute: async (context: VsfContext, { email, customMutation }) => {
    Logger.debug('[Magento]: checkSsoCustomerExists Command', {
      email,
    });

    const { data, errors } = await context.$magento.api.customMutation({
      mutation: customMutation,
      mutationVariables: {
        email: email,
      },
    });

    Logger.debug('[checkSsoCustomerExists Result]:', { data, errors });

    return {
      /* @ts-ignore */
      data: data?.isSSOCustomerExists,
      errors,
    };
  },
};
