var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rk-image--wrapper",attrs:{"data-testid":"image-wrapper"}},[_vm._t("placeholder",function(){return [(_vm.placeholder && _vm.error)?_c('img',_vm._b({staticClass:"rk-image--placeholder",class:{ hidden: !_vm.isPlaceholderVisible },attrs:{"alt":"Placeholder","src":_vm.placeholder,"width":_vm.width || _vm.nuxtImgConfig.width,"height":_vm.height || _vm.nuxtImgConfig.height}},'img',_vm.attributes,false)):_vm._e()]},null,{
      isPlaceholderVisible: _vm.isPlaceholderVisible,
      placeholder: _vm.placeholder,
      width: _vm.width,
      height: _vm.height,
      error: _vm.error,
      nuxtImgConfig: _vm.nuxtImgConfig,
    }),_vm._v(" "),_c('transition',{attrs:{"appear":"","name":_vm.computedTransition}},[_c(_vm.imageComponentTag,_vm._g(_vm._b({tag:"component",class:{ hidden: _vm.error, classes: _vm.classes },style:(_vm.styles),attrs:{"loading":_vm.loading,"src":_vm.src,"alt":_vm.alt,"width":_vm.width || _vm.nuxtImgConfig.width,"height":_vm.height || _vm.nuxtImgConfig.height},on:{"load":function($event){return _vm.onLoad()},"error":function($event){return _vm.onError()}}},'component',_vm.attributes,false),_vm.$listeners))],1),_vm._v(" "),(_vm.$slots.default)?_c('div',{staticClass:"rk-image--overlay"},[_vm._t("default")],2):_vm._e(),_vm._v(" "),_c('noscript',{inlineTemplate:{render:function(){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('img',_vm._b({staticClass:"rk-image rk-image-loaded",attrs:{"src":_vm.src,"alt":_vm.alt,"width":_vm.width,"height":_vm.height}},'img',_vm.$attrs,false))},staticRenderFns:[]}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }