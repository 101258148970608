







































import { defineComponent } from '@nuxtjs/composition-api';
import { useSso } from '~/modules/sso/composables/useSso';

export default defineComponent({
  name: 'SsoLogin',
  setup() {
    const { ssoEnabled, ssoLink } = useSso();

    return {
      ssoEnabled,
      ssoLink,
    };
  },
});
