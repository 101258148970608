import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const generateSsoCustomerTokenCommand = {
  execute: async (context: VsfContext, ssoToken: String, customQuery) => {
    Logger.debug('[generateSsoCustomerTokenCommand]:', { ssoToken });

    const { data, errors } = await context.$magento.api.customQuery({
      query: customQuery,
      queryVariables: {
        ssoToken: ssoToken,
      },
    });

    Logger.debug('[generateSsoCustomerTokenCommand]:', { data, errors });

    return {
      data,
      errors,
    };
  },
};
