





















import { defineComponent } from '@nuxtjs/composition-api';
import { useSso } from '~/modules/sso/composables/useSso';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'SsoLogin',

  setup() {
    const { ssoEnabled, ssoLink } = useSso();
    const { isAuthenticated } = useUser();

    return {
      ssoEnabled,
      ssoLink,
      isAuthenticated,
    };
  },
});
