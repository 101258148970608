import { computed, ref, useContext, useRoute, readonly } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { useConfig, useApi } from '~/composables';
import { Logger } from '~/helpers/logger';
import generateSsoCustomerTokenQuery from '~/modules/sso/composables/useSso/GraphQL/generateSsoCustomerToken.gql';
import { generateSsoCustomerTokenCommand } from './commands/generateSsoCustomerTokenCommand';
import isSSOCustomerExistsMutation from '~/modules/sso/composables/useSso/GraphQL/isSSOCustomerExists.gql';
import { checkSsoCustomerExistsCommand } from './commands/checkSsoCustomerExistsCommand';
import ssoCustomerRegisterQuery from './GraphQL/ssoCustomerRegister.gql';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiNotification } from '~/composables/useUiNotification';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import type { UseSsoInterface } from './useSso';
import { ssoCustomerRegisterInput } from '~/modules/sso/composables/useSso/GraphQL/types';
import type { UseUserErrors, UseUserLoginParams } from '~/modules/customer/composables/useUser';

export function useSso(): UseSsoInterface {
  const { query } = useApi();
  const customerStore = useCustomerStore();
  const loading = ref<boolean>(false);
  const { isAuthenticated } = useUser();
  const { setCart } = useCart();
  const { send: sendNotification } = useUiNotification();
  const { app } = useContext();
  const { config } = useConfig();
  const route = useRoute();
  const context = app.$vsf;
  const ssoEnabled = computed(() => config.value.sso_enabled === '1');
  const referer = computed(() =>
    encodeURIComponent('?referer=' + route.value.path + '&mandatory=1')
  );
  const returnVisitorUrl = computed(() => route.value.path.replace(/^\/+/g, ''));
  const ssoLink = computed(() =>
    isAuthenticated.value
      ? config.value.sso_dashboard_url
      : config.value.sso_login_url + referer.value
  );
  const ssoForgotPasswordLink = computed(
    () => config.value.sso_forgot_password_url + returnVisitorUrl
  );
  const hasRegistered = ref(false);
  const customerLoginRecaptcha = computed(
    () => config.value.customer_login_recaptcha === 'recaptcha_v3'
  );
  const customerAlreadyExists = ref(false);

  const errorsFactory = (): UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
  });
  const error: Ref = ref(errorsFactory());

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  // Standard login taken from useUser and modified to add sso login - used for checkout login
  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async ({
    user: providedUser,
    customQuery,
    customHeaders,
  }: UseUserLoginParams): Promise<void> => {
    Logger.debug('[Magento] useUser.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;
      const modifiedPassword = [providedUser.password, app.$device.userAgent];
      // adding user agent and password to password field
      const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
        {
          email: providedUser.email,
          password: JSON.stringify(modifiedPassword),
          recaptchaToken: providedUser.recaptchaToken,
        },
        customQuery || {},
        customHeaders || {}
      );
      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) =>
          sendNotification({
            icon: 'error',
            id: Symbol(`login_error-${i}`),
            message: registerError.message,
            persist: false,
            title: 'Login error',
            type: 'danger',
          })
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      }

      // set customer token and sso session token
      customerStore.setIsLoggedIn(true);
      apiState.setCustomerToken(data.generateCustomerToken.token);
      /* @ts-ignore */
      if (data.generateCustomerToken.ssoUid) {
        // Set sso cookies and refresh token cookie
        /* @ts-ignore */
        await app.context.$cookies.set('_sa_sso_upid', data.generateCustomerToken.ssoUid, {
          path: '/',
          secure: true,
          domain: config.value.sso_cookie_domain,
          maxAge: 60 * 60 * 24 * 30,
        });
        await apiState.setRefreshToken(true, {
          maxAge: 60 * 45,
        });
        /* @ts-ignore */
        apiState.setSsoSessionToken(data.generateCustomerToken.ssoUid);
      }

      // merge existing cart with customer cart
      const currentCartId = apiState.getCartId();
      const cart = await app.context.$vsf.$magento.api.customerCart();
      const newCartId = cart.data.customerCart.id;

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts({
            sourceCartId: currentCartId,
            destinationCartId: newCartId,
          });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(dataMergeCart.mergeCarts);

          apiState.setCartId(dataMergeCart.mergeCarts.id);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(cart.data.customerCart);
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(cart.data.customerCart);
      }
      error.value.login = null;
    } catch (err) {
      app.$log.error(err);
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      loading.value = false;
    }
  };

  // SSO register use don order confirmation page
  const ssoRegister = async (params: ssoCustomerRegisterInput): Promise<void> => {
    Logger.debug('[Magento] useUser.ssoRegister', params);

    try {
      loading.value = true;

      const { data, errors } = await query<{ ssoCustomerRegister: boolean }>(
        ssoCustomerRegisterQuery,
        {
          ...params,
        }
      );

      Logger.info('[Result]:', { data, errors });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) =>
          sendNotification({
            icon: 'error',
            id: Symbol(`registration_error-${i}`),
            message: registerError.message,
            persist: false,
            title: 'Registration error',
            type: 'danger',
          })
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      }
      error.value.register = null;

      if (data.ssoCustomerRegister) {
        return await new Promise((resolve) => {
          sendNotification({
            id: Symbol('registration_confirmation'),
            message: app.i18n.t(
              'You must confirm your account. Please check your email for the confirmation link.'
            ) as string,
            persist: true,
            title: 'Registration confirmation',
            type: 'success',
            icon: 'check',
          });

          resolve();
          hasRegistered.value = true;
        });
      }
    } catch (err) {
      app.$log.error(err);
      error.value.register = err;
      Logger.error('useUser/register', err);
    } finally {
      loading.value = false;
    }
  };

  // SSO login for post auth when redirecting back from SSO site
  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const ssoLogin = async (token: string): Promise<void> => {
    Logger.debug('[Magento] useUser.ssoLogin', token);
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      customerStore.setIsLoggedIn(true);
      /* @ts-ignore */
      apiState.setCustomerToken(token);
      apiState.setRefreshToken(true, {
        maxAge: 60 * 45,
      });
      apiState.setSsoSessionToken(app.$cookies.get('_sa_sso_upid'));

      // merge existing cart with customer cart
      const currentCartId = apiState.getCartId();
      const cart = await app.context.$vsf.$magento.api.customerCart();
      const newCartId = cart.data.customerCart.id;

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts({
            sourceCartId: currentCartId,
            destinationCartId: newCartId,
          });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(dataMergeCart.mergeCarts);

          apiState.setCartId(dataMergeCart.mergeCarts.id);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(cart.data.customerCart);
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(cart.data.customerCart);
      }
      error.value.login = null;
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/ssoLogin', err);
      app.$log.error(err);
    } finally {
      loading.value = false;
    }
  };

  const handleSsoLogin = async (tokenPayload: any, referer: any): Promise<any> => {
    if (tokenPayload) {
      const { data, errors } = await generateSsoCustomerTokenCommand.execute(
        context,
        tokenPayload.toString(),
        generateSsoCustomerTokenQuery
      );

      if (!errors) {
        /* @ts-ignore */
        await ssoLogin(data.generateSsoCustomerToken.token);
      } else {
        sendNotification({
          id: Symbol('ssoError'),
          message: null,
          type: 'error',
          icon: null,
          persist: false,
          title: app.i18n.t(
            'You did not sign in correctly or your account is temporarily disabled.'
          ) as string,
        });
      }
      if (isAuthenticated) {
        sendNotification({
          id: Symbol('magentoLoginSuccess'),
          message: null,
          type: 'success',
          icon: null,
          persist: false,
          title: app.i18n.t('Successfully logged in') as string,
        });
      } else {
        sendNotification({
          id: Symbol('magentoLoginError'),
          message: null,
          type: 'error',
          icon: null,
          persist: false,
          title: app.i18n.t(
            'You did not sign in correctly or your account is temporarily disabled.'
          ) as string,
        });
      }
    } else {
      await app.router.push(app.localePath('/'));
    }

    // If we have referer query param redirect to that page, otherwise default to home page
    if (referer) {
      await app.router.push(app.localePath(referer.toString()));
    } else {
      await app.router.push(app.localePath('/'));
    }
  };

  const checkSsoCustomerExists = async (email: string): Promise<void> => {
    Logger.debug('useSso.checkSsoCustomerExists', { email });

    try {
      loading.value = true;
      const input = {
        email: email,
        customMutation: isSSOCustomerExistsMutation,
      };
      const { data, errors } = await checkSsoCustomerExistsCommand.execute(context, input);

      if (errors) {
        Logger.error('useSso.checkSsoCustomerExists.errors', errors);
        throw errors[0];
      } else {
        Logger.debug('useSso.checkSsoCustomerExists data', data);
        customerAlreadyExists.value = data;
      }
    } catch (err) {
      Logger.error('useSso.checkSsoCustomerExists.error', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    ssoEnabled,
    ssoLink,
    ssoForgotPasswordLink,
    referer: computed(() => customerStore.referer),
    hasRegistered,
    error: readonly(error),
    customerLoginRecaptcha,
    login,
    ssoRegister,
    handleSsoLogin,
    customerAlreadyExists,
    checkSsoCustomerExists,
  };
}

export default useSso;
