import { render, staticRenderFns } from "./HeaderLogo.vue?vue&type=template&id=32e68cd3"
import script from "./HeaderLogo.vue?vue&type=script&lang=ts"
export * from "./HeaderLogo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkImage: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkImage/RkImage.vue').default,RkLink: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkLink/RkLink.vue').default})
