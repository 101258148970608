




















import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderNavigationItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    extLink: {
      type: [String, Object],
      default: null,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    activeParent: {
      type: Boolean,
      default: false,
    },
    isHere: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Number,
      default: 0,
    },
    isSale: {
      type: [Boolean, Number],
      default: false,
    },
  },
  setup(props) {
    const renderer = ref(props.disable === 1 ? 'span' : 'RkLink');
    return {
      renderer,
    };
  },
});
