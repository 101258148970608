

















import { ref, computed, defineComponent, useFetch, onMounted } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import { useStoryblokBridge } from '@storyblok/nuxt-2';
import { useStoryblokStore } from '~/modules/storyblok/stores/storyblok';

export default defineComponent({
  name: 'HeaderLogo',
  setup() {
    // Get logo from Storyblok and Magento
    // Use Storyblok logo if available, otherwise use Magento logo
    // Storyblok logo is used as a preference so it can be changed easily in the CMS and will still load when Magento is down

    const { config } = useConfig();
    const storyblok = useStoryblokStore();

    useFetch(async () => {
      // Storyblok content
      await storyblok.load();
    });

    const logoSrcMagento = computed(() => {
      const baseMediaUrl = config.value.base_media_url;
      const logo = config.value.header_logo_src;

      return baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';
    });

    const logoSrc = computed(() =>
      storyblok.global.content.logo
        ? storyblok.global.content.logo.filename
        : logoSrcMagento.value || ''
    );
    const logoAlt = computed(() =>
      storyblok.global.content.logo
        ? storyblok.global.content.logo.alt
        : config.value.logo_alt || ''
    );
    const logoWidth = computed(
      () => storyblok.global.content.logoWidth || config.value.logo_width || ''
    );
    const logoHeight = computed(
      () => storyblok.global.content.logoHeight || config.value.logo_height || ''
    );
    const logoSizes = computed(() => `sm:${+logoWidth.value}px lg:${+logoWidth.value * 2}px`);

    return {
      logoAlt,
      logoHeight,
      logoSizes,
      logoSrc,
      logoWidth,
    };
  },
});
