//
//
//
//
//
//

import { focus } from '~/rockitUI/components/utilities/directives';
import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkLink',
  directives: { focus },
  props: {
    link: {
      type: [String, Object],
      default: '',
    },
  },
  setup(props) {
    const { app } = useContext();

    const isExternal = computed(() => {
      return typeof props.link === 'string' && props.link.search(/(^\/|^#)/g) === -1;
    });

    const isNativeLinkTag = computed(() => {
      return isExternal.value || !app.router;
    });

    const urlTag = computed(() => {
      return isNativeLinkTag.value ? { href: props.link } : { to: props.link || '' };
    });

    const linkComponentTag = computed(() => {
      const routerLink = app.nuxt ? 'nuxt-link' : 'router-link';
      return isNativeLinkTag.value ? 'a' : routerLink;
    });

    return {
      urlTag,
      linkComponentTag,
      isExternal,
      isNativeLinkTag,
    };
  },
});
